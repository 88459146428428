import React, { useState, useEffect } from "react";
import "./webflow.css";
import "./try-habitual.webflow.css";
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import Logo from "../svg/H-Logo.svg";
import youCanChange from "../svg/You-can-change.svg";
import benefit1 from "../svg/Benefit-Icon-1.svg";
import benefit2 from "../svg/Benefit-Icon-2.svg";
import benefit3 from "../svg/Benefit-Icon-3.svg";
import benefit4 from "../svg/Benefit-Icon-4.svg";
import featured1 from "../images/Featured-Logo-1.png";
import featured2 from "../images/Featured-Logo-2.png";
import featured3 from "../images/Featured-Logo-3.png";
import featured4 from "../images/Featured-Logo-4.png";
import featured5 from "../images/Featured-Logo-5.png";
import guardian from "../images/The_Guardian.png";
import care from "../svg/Care-Icon.svg";
import trustpilot from "../svg/Trustpilot.svg";
import stars from "../images/Stars.png";
import gareth from "../images/3-gareth-min.png";
import prem from "../images/2-prem-min.png";
import bula from "../images/5-bula-min.png";
import sheila from "../images/7-sheila-min.png";
import dervis from "../images/11-dervis-min.png";
import unLogo2 from "../images/un-logo-2.png";
import unLogo3 from "../images/un-logo-3.png";
import unLogo4 from "../images/un-logo-4.png";
import nextSteps from "../images/Next-steps.png";
import mobileHero from "../images/Ledger-Mobile-Hero.png";
import mobilePatients from "../images/patients-mobile-min.jpg";
import habitualSachets from "../images/habitual-sachets.gif";
import phone from "../images/ezgif.com-gif-maker-1.gif";
import phone0 from "../images/ezgif.com-gif-maker.gif";
import { trackPageView } from "../helpers/segment-helper";
import { scrollToTop } from "../helpers/utility-helper";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";
import Button from "../components/Button.component";
import { Transition } from "react-transition-group";
import settings from "../styles/global/settings";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

const HabitualNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [isDDOpen, setIsDDOpen] = useState(false);
  const toggleDD = () => setIsDDOpen(!isDDOpen);
  const [showHeaderButton, setShowHeaderButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 80) {
        if (!showHeaderButton) {
          setShowHeaderButton(true);
        }
      } else if (showHeaderButton) {
        setShowHeaderButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showHeaderButton]);

  return (
    <>
      <div>
        <Navbar
          style={{ backgroundColor: "#fff", zIndex: 9999 }}
          color="white"
          light
        >
          <NavbarBrand
            href="https://www.tryhabitual.com/"
            className="brand w-nav-brand w--current"
            style={{ padding: 8 }}
          >
            {/* <img src={logo} width="196" alt="Habitual" className="logo" /> */}
            <Logo width="196" className="logo" />
          </NavbarBrand>
          {showHeaderButton && !isMobile && (
            <>
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <Button
                  // style={{ maxWidth: "150px", marginLeft: "auto" }}
                  alignEnd={true}
                  square={true}
                  title={"Personalise my plan"}
                  onClick={() => {
                    if (window && window.location) {
                      window.location.href = `https://apply.tryhabitual.com/`;
                    }
                  }}
                />
              </div>
              <div
                className="intercom-launch-button"
                style={{
                  marginLeft: "auto",
                }}
              >
                <Button
                  // style={{ maxWidth: "150px", marginLeft: "auto" }}
                  alignEnd={true}
                  square={true}
                  tertiary
                  title={"Chat with our Health Team"}
                  onClick={() => {
                    // console.log("click")
                  }}
                />
              </div>
            </>
          )}
          <NavbarToggler
            onClick={() => toggle()}
            className="icon w-icon-nav-menu"
            style={{
              backgroundColor: "#fff",
              marginLeft: "auto",
              fontSize: 24,
            }}
          />
          {isOpen && (
            <div
              className="div-block-31"
              style={{ zIndex: 99999, left: 0, right: 0, top: 96 }}
            >
              <NavLink
                className="nav-link w-nav-link w--current"
                href="https://www.tryhabitual.com/"
              >
                Home
              </NavLink>
              <NavLink
                className="nav-link w-nav-link"
                href="https://www.tryhabitual.com/patient-stories"
              >
                Patient stories
              </NavLink>
              <NavItem
                onClick={() => toggleDD()}
                className="nav-link w-nav-link"
              >
                Programme
              </NavItem>
              {isDDOpen && (
                <div>
                  <NavLink
                    className="dropdown-link w-dropdown-link"
                    href="https://www.tryhabitual.com/total-diet-replacement"
                    style={{
                      color: settings.color.primary_1,
                      fontSize: 18,
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Total diet replacement
                  </NavLink>
                  <NavLink
                    className="dropdown-link-2 w-dropdown-link"
                    href="https://www.tryhabitual.com/digital-behaviour-change"
                    style={{
                      color: settings.color.primary_1,
                      fontSize: 18,
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Behaviour change
                  </NavLink>
                  <NavLink
                    className="dropdown-link-3 w-dropdown-link"
                    href="https://www.tryhabitual.com/journey"
                    style={{
                      color: settings.color.primary_1,
                      fontSize: 18,
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    The journey
                  </NavLink>
                </div>
              )}
              <NavLink
                className="nav-link w-nav-link"
                href="https://www.tryhabitual.com/pricing"
              >
                Pricing
              </NavLink>
              <NavLink
                className="nav-link w-nav-link"
                href="https://www.tryhabitual.com/science"
              >
                Science
              </NavLink>
              <NavLink
                className="nav-link w-nav-link"
                href="https://www.tryhabitual.com/journal"
              >
                Journal
              </NavLink>
              <div className="menu-divider"></div>
              <NavLink
                className="nav-link w-nav-link"
                href="https://habitual.knack.com/habitualtracking"
              >
                Login
              </NavLink>
            </div>
          )}
          {showHeaderButton && isMobile && (
            <div
              style={{
                position: "absolute",
                top: "51px",
                left: "0px",
                right: "0px",
                backgroundColor: settings.color.primary_1,
                display: "flex",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                <Button
                  mobileStyle
                  alignEnd={true}
                  square={true}
                  title={"Personalise my plan"}
                  onClick={() => {
                    if (window && window.location) {
                      window.location.href = `https://apply.tryhabitual.com/`;
                    }
                  }}
                />
              </div>
              <div
                className="intercom-launch-button"
                style={{
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              >
                <Button
                  mobileStyle
                  // style={{ maxWidth: "150px", marginLeft: "auto" }}
                  alignEnd={true}
                  square={true}
                  tertiary
                  title={"Chat with our Health Team"}
                  onClick={() => {
                    // console.log("click")
                  }}
                />
              </div>
            </div>
          )}
        </Navbar>
      </div>
    </>
  );
};

const duration = 1000;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const CrossFadingImage = () => {
  const images = [gareth, prem, bula, sheila, dervis];
  const [showImage, setShowImage] = useState(true);
  const [imageSrc, setImageSrc] = useState(images[0]);

  useEffect(() => {
    let intervalSetClass;
    let counter = 0;
    intervalSetClass = setInterval(() => {
      setShowImage(true);
      setImageSrc(images[counter]);
      counter += 1;

      if (counter === images.length) {
        counter = 0;
      }
      setTimeout(() => {
        setShowImage(false);
      }, duration);
    }, 2500);
    return () => {
      clearInterval(intervalSetClass);
    };
  }, []);

  return (
    <Transition in={showImage} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <img key={"image"} src={imageSrc} />
        </div>
      )}
    </Transition>
  );
};

const Header = () => {
  return (
    <>
      <HabitualNavBar />
      <div className="h-header letger-header">
        <div className="h-container">
          <div className="h-hero-content">
            <div
              id="w-node-_19634334-b023-69e6-6322-c7a3813f4ed4-7fa066e3"
              className="h-hero-wrapper left-480"
            >
              <h1 className="h-hero-title letger-hero-title">
                A new approach to <br />
                type 2 diabetes.
              </h1>
              <div className="ledger-sub-header">
                Personalised, doctor-led diabetes
                <br />
                remission programmes.
              </div>
              <img
                src={mobilePatients}
                loading="lazy"
                alt=""
                className="ledger-mobile-hero-image"
              />
              {/* <div className="html-embed-3 w-embed w-script">
              <script
                charset="utf-8"
                type="text/javascript"
                src="https://js.hsforms.net/forms/v2.js"
              ></script>
              <script>
                hbspt.forms.create({
                  region: "na1",
                  portalId: "7172254",
                  formId: "764cc76a-0636-4d5e-be86-edb996ff4d6e",
                  onFormSubmit: function ($form) {
                    fbq("track", "Lead");
                  },
                });
              </script>
            </div> */}
              {/* {submitted ? (
                <div
                  style={{
                    color: "#5DA7EB",
                    fontFamily: "DM Sans",
                    fontFize: "18px",
                    lineHeight: "130%",
                    fontWeight: "500",
                    marginTop: 15,
                    marginBottom: 4,
                  }}
                >
                  Submission received
                </div>
              ) : (
                <div>
                  <div style={{ marginTop: 24 }}>Enter your phone number</div>
                  <PhoneInput
                    label={"What’s your mobile number?"}
                    value={phone}
                    onChange={phone => changePhoneWithEvent(phone, setPhone)}
                    placeholder={"07912345678*"}
                    errorMessage={phoneError}
                  />
                  <Button
                    square={true}
                    title={"Join the list"}
                    onClick={() => submitForm(phone)}
                  />
                </div>
              )} */}
              <div style={{ marginTop: 8, width: "80%" }}>
                <Button
                  square={true}
                  title={"Personalise my plan"}
                  onClick={() => {
                    if (window && window.location) {
                      window.location.href = `https://apply.tryhabitual.com/`;
                    }
                  }}
                />
              </div>
              <div
                className="intercom-launch-button"
                style={{ marginTop: 8, width: "80%" }}
              >
                <Button
                  square={true}
                  tertiary
                  title={"Chat with our Health Team"}
                  onClick={() => {
                    // console.log("click")
                  }}
                />
              </div>
              {/* <div className="ledger-header-tooltip">
                <img
                  src={arrow}
                  loading="lazy"
                  width="26"
                  alt=""
                  className="image-26"
                />
                <div>
                  <strong>
                    <em>Includes free access</em>
                  </strong>
                  <em> to our diabetes remission course and community!</em>
                </div>
              </div> */}
            </div>
            <div
              id="w-node-_45ad082b-2d2d-37ce-ea7f-70fc0d4b1887-7fa066e3"
              className="ledger-hero-image-wrapper"
            >
              <CrossFadingImage />
              {/* <img
                src={gareth}
                loading="lazy"
                style={{ opacity: 1 }}
                data-w-id="3bedde37-62b6-ffe5-29aa-5496277bb44b"
                alt=""
                className="h-hero-image"
              /> */}
              {/* <img
                src={prem}
                loading="lazy"
                style={{ opacity: 0 }}
                data-w-id="209f7d19-cd24-5da4-3fdb-483263f14d92"
                alt=""
                className="h-hero-image"
              /> */}
              {/* <img
                src={bula}
                loading="lazy"
                style={{ opacity: 0 }}
                data-w-id="fec42a82-dfeb-0de6-b885-9f032e703de4"
                alt=""
                className="h-hero-image"
              />
              <img
                src={sheila}
                loading="lazy"
                style={{ opacity: 0 }}
                data-w-id="2abf2b2b-5d07-1436-621d-772f88fce210"
                alt=""
                className="h-hero-image"
              />
              <img
                src={dervis}
                loading="lazy"
                style={{ opacity: 0 }}
                data-w-id="c5bac387-c249-21d0-2baf-a419bb87e29d"
                alt=""
                className="h-hero-image"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MiddleSection = () => (
  <>
    <div className="h-section path-grey">
      <div className="h-container w-container">
        <div className="h-content flex">
          <div className="h-left-title-wrapper">
            <h5>A first of its kind programme built by experts from</h5>
          </div>
          <div className="h-column flex-center wrap">
            <img
              src={unLogo2}
              loading="lazy"
              alt=""
              className="h-university-logo"
            />
            <img
              src={unLogo3}
              loading="lazy"
              alt=""
              className="h-university-logo"
            />
            <img
              src={unLogo4}
              loading="lazy"
              alt=""
              className="h-university-logo"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="h-section" style={{ backgroundColor: "white" }}>
      <div className="h-container w-container">
        <div className="h-section-hero">
          <h2 className="h-section-title small-width large">
            Lifelong benefits, whatever your goal.
          </h2>
        </div>
        <div className="h-content grid center-grid">
          <div className="h-icon-box">
            <div className="h-icon-box-icon">
              <img src={benefit1} loading="lazy" alt="" />
            </div>
            <h3>Put type 2 into remission</h3>
            <div>
              Up to 60% of patients can reach remission. No pills, no
              complications.
            </div>
          </div>
          <div className="h-icon-box">
            <div className="h-icon-box-icon">
              <img src={benefit2} loading="lazy" alt="" />
            </div>
            <h3>Stop medication</h3>
            <div>
              We help our patients to safely reduce medication use alongside
              weight loss.
            </div>
          </div>
          <div className="h-icon-box">
            <div className="h-icon-box-icon">
              <img src={benefit3} loading="lazy" alt="" />
            </div>
            <h3>Live longer and happier</h3>
            <div>
              Reduce the risk of complications and live the life you’ve been
              dreaming of.
            </div>
          </div>
          <div className="h-icon-box">
            <div className="h-icon-box-icon">
              <img src={benefit4} loading="lazy" alt="" />
            </div>
            <h3>Lose 15% body weight</h3>
            <div>
              Our patients lost an average of 16 kg and sustained it for a year.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="content-section grey-bg">
      <div className="wide-container w-container">
        <h1 className="heading-7 align-center">
          Our 3-part programme is designed for disease reversal
        </h1>
        <div className="columns align-top w-row">
          <div className="no-padding-mobile w-col w-col-4">
            <div className="_3-part-program-card">
              <div className="program-card-header w-row">
                <div className="w-col w-col-8 w-col-small-8 w-col-tiny-8">
                  <div className="copy-small green-underline">Part 1</div>
                  <h3 className="program-card-heading">
                    Lose weight, quickly and safely
                  </h3>
                </div>
                <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                  <img src={habitualSachets} loading="lazy" width="97" alt="" />
                </div>
              </div>
              <div className="copy-small">
                Expert-formulated soups, shakes, and porridges help our patients
                lose enough weight to reverse pre-diabetes and type 2 diabetes
              </div>
            </div>
          </div>
          <div className="no-padding-mobile w-col w-col-4">
            <div className="_3-part-program-card">
              <div className="program-card-header w-row">
                <div className="w-col w-col-9 w-col-small-9 w-col-tiny-9">
                  <div className="copy-small blue-underline">Part 2</div>
                  <h3 className="program-card-heading">
                    Change your habits from day one
                  </h3>
                </div>
                <div className="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <img src={phone0} loading="lazy" width="94" alt="" />
                </div>
              </div>
              <div className="copy-small padding-bottom-20">
                Our personalised habit-change program helps you make lasting
                changes to your nutritional, mental, and physical habits
              </div>
            </div>
          </div>
          <div className="no-padding-mobile w-col w-col-4">
            <div className="_3-part-program-card">
              <div className="program-card-header w-row">
                <div className="w-col w-col-9 w-col-small-9 w-col-tiny-9">
                  <div className="copy-small yellow-underline">Part 3</div>
                  <h3 className="program-card-heading">
                    Sustain change with daily support
                  </h3>
                </div>
                <div className="w-col w-col-3 w-col-small-3 w-col-tiny-3">
                  <img src={phone} loading="lazy" width="94" alt="" />
                </div>
              </div>
              <div className="copy-small">
                Get support from your social support group made up of people on
                the same journey as you, whenever you need it
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="h-section light-green">
      <div className="h-container w-container">
        <div className="h-content flex">
          <div className="h-left-title-wrapper _60-col icon-flex">
            <img src={care} loading="lazy" alt="" />
            <h2 className="h-cta-title left">Diabetes care, reimagined.</h2>
          </div>
          <div className="h-column mobile-center-text">
            <div>
              Pills and annual diabetes check-ups just aren’t enough. We’re here
              to change that.
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const BottomSection = () => (
  <>
    <div className="h-section ground-grey tablet-bg-next-steps">
      <div className="h-container w-container">
        <div className="h-content flex">
          <div className="h-column tablet-padding">
            <h2 className="h-section-title bottom-margin">
              A programme built around you.
            </h2>
            <div>
              We take only small patient cohorts to ensure you get all the
              support you need
            </div>
            <ul role="list" className="h-arrow-list">
              <li className="h-arrow-list-item">
                <strong>Personalised medical onboarding</strong> to ensure your
                safety
              </li>
              <li className="h-arrow-list-item">
                <strong>Support </strong>from our Patient Care team, at your
                convenience
              </li>
              <li className="h-arrow-list-item last">
                <strong>Individual maintenance</strong> plans to help you stay
                healthy for life
              </li>
            </ul>
          </div>
          <div className="h-column shrink">
            <img
              src={nextSteps}
              loading="lazy"
              alt=""
              className="h-next-steps-img"
            />
          </div>
        </div>
      </div>
    </div>
    <YellowCTAs />
    <div
      className="h-section bg-social-proof"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="h-container w-container">
        <div
          className="h-section-hero small-center"
          style={{ marginTop: "-50px" }}
        >
          <div className="h-content center">
            <h1
              className="h-section-title large bottom-margin"
              style={{ marginBottom: "50px" }}
            >
              If you’re here, you’re ready for this
            </h1>
            <img
              src={mobileHero}
              loading="lazy"
              alt=""
              className="h-cta-image"
            />
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                marginTop: "10px",
              }}
              className="h-section-subtitle-text"
            >
              Changing your health will never be easy. But it will always be
              worth it.
            </p>
            <Button
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              title={"Personalise my plan"}
              onClick={() => {
                if (window && window.location) {
                  window.location.href = `https://apply.tryhabitual.com/`;
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

const Reviews = () => {
  return (
    <div className="h-section" style={{ backgroundColor: "white" }}>
      <div className="h-section-hero mobile-padding">
        <h2 className="h-section-title">
          Learn from the experts... our patients
        </h2>
      </div>
      <Marquee velocity={isMobile ? 2 : 1}>
        {times(5, String).map((id) => (
          <div key={id} className="h-reviews-group">
            <div className="h-review-item green">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">Life changing program</h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  The HABITUAL program has been truly Life Changing for Me! I
                  have managed to lose 7 Stone since February 2020 but more
                  importantly I am mindful of what I eat on a daily basis. I
                  have learned to enjoy a treat without returning to my old
                  eating habits...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Miss Maggie
                      <br />
                      Fitzgerald
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Superb - it works!
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  Superb program - I lost over 20kg that will stay off thanks to
                  the combination of food advice, mental health support and
                  physical exercise tips. I was 97kg in January and it&#x27;s
                  now October and I&#x27;m down to 75kg - so 3.5 stone loss /
                  22kg. I would highly recommend Habitual ...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Gareth Dixon
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item yellow">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Habitual are the best
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  One of the most changing life style experience. Habitual team
                  where 100% behind me. Encouraging me all the way step by step
                  even with my off days Hebtural was there taught me to
                  understand my addiction to food and...
                  <br />
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Bula Butch
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Superb and Outstanding Weight Loss Programme!
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  The weight loss programme, offered and run by Habitual is
                  absolutely first-class and highly recommended, run by an
                  extremely professional, kind, courteous, friendly and
                  supportive team...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Elizabeth Wayling
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item green">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">A new way of life</h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  The information you receive to go alone with the nutritional
                  support during the diet replacement phase is durables and
                  important. The support the team at habitual give you via
                  emails, chats and medical support is of the highest caliber...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>Darren Bailey</strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Fully Recommend Habitual
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  Fully recommend Habitual Fully recommend Habitual! If you want
                  to change your life do this! I lost 11kg and feel great! Easy
                  to follow meal replacement sachets. Also support with
                  psychological reasons we put on weight. Never did ...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Kim Fisher
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item yellow">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  The programme changed my life (and...
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  The programme changed my life (and shape). I am physically
                  better and no longer take the medication I was previously
                  taking. The support and information offered by the team was
                  invaluable. At the outset I was cynical that this would
                  work...
                  <br />
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Customer
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Best thing that happened to me in this
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  Best thing happened to me in this country. The staff and the
                  support are second to none! Highly recommend to all my family
                  and friends! You must try- your health is priceless!
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>Nadia Stancheva </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item green">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Habitual has changed my life for the better
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  Habitual has changed my life! I’ve gone from being scared of
                  tomorrow, to now being excited to experience what the future
                  will bring.I feel younger, happier &amp; healthier and could
                  not recommend the program enough to anyone else looking...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>Max Black</strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Habitual has opened so many new doors...
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  Habitual has opened so many new doors for me. Their program
                  and the team I interfaced with are top notch, supportive,
                  helpful and caring; creating such a comforting environment
                  that has allowed me to look at myself in a new light...
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Randi Ransom
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item yellow">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Amazing Program, Amazing Team
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  This program takes care of the whole you, the whole way. From
                  effective (&amp; safe) weight loss products to advice on how
                  to change your habits, it’s a refreshing twist on traditional
                  diet programs. Plus, the team behind it is amazing, brilliant,
                  and fun!
                  <br />
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      C Carroll
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
            <div className="h-review-item">
              <div className="h-review-item-title-wrapper">
                <h3 className="h-review-item-title">
                  Really good
                  <br />
                </h3>
              </div>
              <div className="h-review-item-text-wrapper">
                <div>
                  Really good! Highly Recommend
                  <br />
                </div>
              </div>
              <div className="h-review-item-bottom">
                <div className="h-author-name">
                  <div>
                    <strong>
                      Champ Biala
                      <br />
                    </strong>
                  </div>
                  <div>
                    Nov 14, 2020
                    <strong>
                      <br />
                    </strong>
                  </div>
                </div>
                <img src={stars} loading="lazy" alt="" />
              </div>
            </div>
          </div>
        ))}
      </Marquee>
      <div className="h-section-bottom">
        <div>
          <strong>Rated Excellent</strong>
        </div>
        <img
          src={trustpilot}
          loading="lazy"
          alt=""
          className="h-trust-pilot-logo"
        />
      </div>
    </div>
  );
};
const YouCanChange = () => {
  return (
    <div className="h-text-slide-carousel-wrapper">
      <Marquee velocity={isMobile ? 8 : 30}>
        {times(20, String).map((id) => (
          <img key={id} src={youCanChange} loading="lazy" alt="" />
        ))}
      </Marquee>
    </div>
  );
};

const Footer = () => (
  <div className="footer">
    <div className="wide-container w-container">
      <div className="w-row">
        <div className="w-col w-col-3">
          <h3 className="heading-3 white">Explore</h3>
          <a href="https://www.tryhabitual.com/clinicians" className="link">
            For clinicians
          </a>
          <a href="https://www.tryhabitual.com/team" className="link">
            Team
          </a>
        </div>
        <div className="w-col w-col-3">
          <h3 className="heading-3 white">Follow us</h3>
          <a
            href="https://www.linkedin.com/company/tryhabitual"
            className="link"
          >
            Linkedin
          </a>
          <a href="https://twitter.com/tryhabitual" className="link">
            Twitter
          </a>
        </div>
        <div className="w-col w-col-3">
          <h3 className="heading-3 white">Legal</h3>
          <a
            href="https://www.tryhabitual.com/journal/privacy"
            className="link"
          >
            Privacy
          </a>
          <a href="https://www.tryhabitual.com/journal/terms" className="link">
            T&amp;Cs
          </a>
        </div>
        <div className="w-col w-col-3">
          <h3 className="heading-3 white">Talk to us</h3>
          <a href="https://angel.co/company/tryhabitual/jobs" className="link">
            Join our team
          </a>
          <a
            href="mailto:hello@tryhabitual.com?subject=Email%20from%20website"
            className="link"
          >
            hello@tryhabitual.com
          </a>
        </div>
      </div>
    </div>
    {/* <div
      key={`gatsby-plugin-intercom`}
      dangerouslySetInnerHTML={{
        __html: 'var APP_ID = "r2o3yn2s";window.intercomSettings = {app_id: APP_ID, custom_launcher_selector: ".intercom-launch-button",};(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${pluginOptions.appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()',
      }}
    /> */}
  </div>
);

const Featured = () => (
  <div
    data-w-id="ae520dfe-5a67-c39a-f131-b0751e2d5c4e"
    className="featured-logo-slider-wrapper"
  >
    <div className="wide-container w-container">
      <h3 className="align-center">As featured in</h3>
    </div>
    <div className="featured-logo-slider">
      <Marquee velocity={40}>
        {times(5, String).map((id) => (
          <div key={id} className="featured-logos-column">
            <img
              src={featured2}
              loading="lazy"
              alt=""
              className="featured-logo"
            />
            <img
              src={featured5}
              loading="lazy"
              alt=""
              className="featured-logo"
            />
            <img
              src={guardian}
              loading="lazy"
              alt=""
              className="featured-logo"
            />
            <img
              src={featured3}
              loading="lazy"
              alt=""
              className="featured-logo"
            />
            <img
              src={featured1}
              loading="lazy"
              alt=""
              className="featured-logo"
            />
            <img
              src={featured4}
              loading="lazy"
              alt=""
              className="featured-logo"
            />
          </div>
        ))}
      </Marquee>
    </div>
  </div>
);

const YellowCTAs = () => (
  <div
    style={{
      backgroundColor: "#FFE768",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    }}
  >
    <div
      style={{
        flex: "1",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          margin: "auto",
          paddingTop: "120px",
          paddingBottom: "120px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <h2 style={{ fontSize: "32px" }}>Want to try us out?</h2>
        <Button
          teal
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "35px",
            marginBottom: "15px",
          }}
          title={"Get my taster box"}
          onClick={() => {
            if (window && window.location) {
              window.location.href = `https://www.tryhabitual.com/product/taster-week`;
            }
          }}
        />
      </div>
    </div>
    <div
      style={{
        flex: "1",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        backgroundColor: settings.color.primary_3,
      }}
    >
      <div
        style={{
          margin: "auto",
          paddingTop: "120px",
          paddingBottom: "120px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <h2 style={{ fontSize: "32px" }}>Want to talk to us?</h2>
        <div className="intercom-launch-button">
          <Button
            teal
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "35px",
              marginBottom: "15px",
            }}
            title={"Chat to our Health Team"}
            onClick={() => {
              // console.log("click")
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Welcome() {
  useEffect(() => {
    scrollToTop();
    trackPageView("apply");
    if (window && window.intercomSettings) {
      window.intercomSettings = {
        app_id: "r2o3yn2s",
        custom_launcher_selector: ".intercom-launch-button",
      };
    } else {
      // console.log("intercom not loaded", window.Intercom);
    }
  }, []);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400&family=DM+Serif+Display&display=swap"
          rel="stylesheet"
        />
        <meta charSet="utf-8" />
        <title>Habitual — Type 2 diabetes reversal and prevention</title>
        <link rel="canonical" href="https://apply.tryhabitual.com/" />
      </Helmet>
      <div
        style={{
          backgroundColor: "#f5f8f2",
          minHeight: "100vh",
          fontFamily: "DM Sans",
        }}
      >
        {/* <div dangerouslySetInnerHTML={{ __html: headerhtml }} /> */}
        <Header />
        <YouCanChange />
        <MiddleSection />
        <Reviews />
        <BottomSection />
        <Featured />
        <Footer />
      </div>{" "}
    </>
  );
}
